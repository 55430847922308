<template>
  <div
    v-if="paginatedProducts"
    class="products row"
  >
    <div
      v-for="product in paginatedProducts"
      :key="product.product.id"
      class="product col-lg-4 my-4"
    >
      <div 
        :id="`product-image-${product.product.id}`"
        class="thumbnail-top" 
        :style="{ 'backgroundImage': `url(${product.product?.cover?.bySize?.home_default?.url})` }"
      >
        <a
          :href="product.product.url"
          class="thumbnail product-thumbnail d-flex w-100 h-100 justify-content-end align-items-end" 
        >
          <span 
            v-if="Math.round(product.original_price) * 100 > Math.round(product.product.price_amount) * 100"
            class="price-percent-reduction rounded text-white bg-primary py-1 px-2 m-2"
          >
            -{{ reduction(product.original_price, product.product.price_amount) }}%
          </span>
        </a>
      </div>    
      <div class="d-flex align-items-center py-2">
        <template
          v-for="flag in product.product.flags"
        >
          <span 
            v-if="flag.type === 'new'"           
            :key="flag.type" 
            :class="`product-flag ${flag.type} pr-2`"
          >{{ newText }}
          </span>
        </template>
        <div
          v-if="product.product.main_variants"
          class="variant-links d-flex gap-1 align-items-center"
        >
          <a
            v-for="(variant, index) in product.product.main_variants"
            :id="`variant-${variant.id_product_attribute}`"     
            :key="variant.id_product_attribute"       
            :href="variant.url"
            :title="variant.name"
            :class="[
              variant.type,
              `variant-product-${variant.id_product}`,
              'rounded-circle p-0 mx-1',
              index === 0 ? 'active' : ''
            ]"  
            :aria-label="variant.name"
            :style="variant.html_color_code ? `background-color:${variant.html_color_code}` : null"
            @mouseover="showPicture(variant.id_product_attribute, product)"
          />
        </div>
      </div>  
      <div class="product-description">
        <h3 class="product-title text-uppercase">
          <a :href="product.product.url">{{ product.product.name }}</a>
        </h3>
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="displayPrice(product)" />
        <!--eslint-enable-->
      </div>
    </div>
    <div class="w-100 text-center"> 
      <p
        v-if="productPagination"
        class="text-center pt-5"
      >
        {{ productPagination }}
      </p>
      <button
        v-if="currentPage * maxPerPage < displayProducts.length"
        class="btn btn-primary d-inline-block my-2"
        @click="loadMore"
      >
        {{ loadMoreText }}
      </button>
    </div>
  </div>
</template>

<script type="ts">

export default {
  name: 'ProductListing',
  props: {
    sortObject: {
      type: Array,
      required: true,
    }    
  },
  data(){
    return {
      products: window.unige_products,
      categories: window.unige_categories,
      displayProducts: {},
      currentPage: 1,
      currentCategory: null,
      maxPerPage:6,
      showReadMore: true,
      loadMoreText: window.t_load_more,
      newText: window.t_new,
      productPagination: null,
      paginatedProducts: null,
      search: null,
      url: new URL(location),
    }
  },
  computed: {
    reduction(){
      return (original_price, price) => {
        return Math.round((original_price - price) / original_price * 100)
      }
    },
    displayPrice(){
      return (product) => {
        if (Math.round(product.original_price) * 100 > Math.round(product.product.price_amount) * 100){
          return `<span class="text-primary">${product.product.price}</span> <del>${product.formatted_original_price}</del>`
        }else{
          return `${product.product.price}`
        }
      }
    }
  },
  mounted(){
    this.displayProducts = this.products
    this.sortProduct(1)
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has('page')){
      this.currentPage = parseInt(urlSearchParams.get('page'))
    }

    this.pagination()

    if (this.url.hash){
      const match = this.url.hash.match(/^#\/(\d+)/);
      if (match){
        this.currentCategory = match[1]
      }
      this.displayProducts = this.products.filter( product => {
        return Object.keys(product.categories).includes(this.currentCategory.toString())
      })
      this.$root.$emit('init-cat', this.currentCategory)
      this.pagination()
    }
    this.$root.$on('search-product', (search) => this.searchProduct(search) )
    this.$root.$on('reset-search', () => {
      this.search = null
      this.currentPage = 1
      if (this.currentCategory){
        this.displayProducts = this.products.filter( product => {
          return Object.keys(product.categories).includes(this.currentCategory.toString())
        })
      }else{
        this.displayProducts = this.products
      }
      this.pagination()
      this.setUrl()
    })
    this.$root.$on('sort-product', (id) => this.sortProduct(id) )
    this.$root.$on('filter-cat', (id) => {
      this.currentCategory = id
      this.currentPage = 1
      if (id){
        this.displayProducts = this.products.filter( product => {
          return Object.keys(product.categories).includes(id.toString())
        })
      }else{
        this.displayProducts = this.products
      }
      if (this.search){
        this.searchProduct(this.search)
      }
      this.pagination()
      this.setUrl(id)
    })
  },
  methods: {
    setUrl(id){
      const link_rewrite = this.categories.filter( cat => cat.id_category === parseInt(id))
      if (link_rewrite.length > 0){
        const category_link = `#/${id}-${link_rewrite[0].link_rewrite}`
        history.pushState({}, "", category_link);      
      }else{
        history.pushState({}, "", window.location.pathname);
      }
    },
    pagination(){
      this.paginatedProducts = Object.entries(this.displayProducts).slice(0, this.currentPage * this.maxPerPage).map(entry => entry[1]);
      this.url.searchParams.set('page', this.currentPage)
      if (this.currentPage > 1){
        history.pushState({}, "", this.url)
      }
    
      this.productPagination = window.t_product_pagination.replace('%product', this.paginatedProducts.length).replace('%total', this.displayProducts.length)
    },
    loadMore() {
      this.currentPage += 1
      this.pagination()
    }, 
    searchProduct(search){
      this.search = search
      this.displayProducts = this.displayProducts.filter(d => d.product.name.toLowerCase().includes(search.toLowerCase()))
      this.currentPage = 1
      this.pagination()
    },
    sortProduct(id){
      switch(id){    
        case 1:
        this.displayProducts.sort((a,b) => {
          const date_add_a = new Date(a.product.date_add);
          const date_add_b = new Date(b.product.date_add);
          return date_add_a - date_add_b;
          })   
          this.displayProducts.sort((a,b) => {
          if (b.product.new < a.product.new){
              return -1
            }else{
              return 1
            }
          })      
          break
        case 2:
        this.displayProducts.sort((a,b) => {
          if (a.order < b.order){
              return -1
            }else{
              return 1
            }
          })        
          break              
        // Price ASC
        case 3:
        this.displayProducts.sort((a,b) => {
            return a.product.price_amount - b.product.price_amount 
          })          
          break
        // Price DESC
        case 4:
          this.displayProducts.sort((a,b) => {
            return b.product.price_amount - a.product.price_amount 
          })
          break
        // Name ASC
        case 5:
          this.displayProducts.sort((a,b) => {
            if (b.product.name > a.product.name){
              return -1
            }else if (b.product.name < a.product.name){
              return 1
            }
            return 0
          })
          break
        // Name DESC
        case 6:
        this.displayProducts.sort((a,b) => {
            if (b.product.name < a.product.name){
              return -1
            }else if (b.product.name > a.product.name){
              return 1
            }
            return 0
          })
          break                    
      }
      this.currentPage = 1
      this.pagination()
    },
    showPicture(variant, product){

      document.querySelectorAll(`.variant-product-${product.product.id}`).forEach( variant => {
        variant.classList.remove('active')
      })

      document.querySelector(`#variant-${variant}`).classList.add('active')

      const new_image = product.images.filter( image  => {
        return image.associatedVariants.includes(variant)
      });
      if (new_image.length > 0){
        const img = document.querySelector(`#product-image-${product.product.id}`)
        img.style.backgroundImage = `url(${new_image[0].bySize.home_default.url})`
      }

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>