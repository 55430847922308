<template>
  <div
    id="app"
    class="container p-0"
  >
    <div class="d-lg-flex flex-column pb-2">
      <CategoryList />
      <div class="d-flex flex-column flex-md-row align-items-left align-items-md-none justify-content-between">
        <SearchField class="mb-4 mb-md-0" />
        <SortField :sort-object="sortObject" />
      </div>
    </div>
    <ProductListing :sort-object="sortObject" />
  </div>
</template>

<script>
import CategoryList from './components/CategoryList.vue'
import ProductListing from './components/ProductListing.vue'
import SearchField from './components/SearchField.vue'
import SortField from './components/SortField.vue'

export default {
  name: 'App',
  components: {
    CategoryList,
    ProductListing,
    SearchField,
    SortField,
  },
  data(){
    return {
      sortObject: [
        {id: 1, name: window.t_new},
        {id: 2, name: window.t_best_sellers},
        {id: 3, name: window.t_price_asc},
        {id: 4, name: window.t_price_desc},
        {id: 5, name: window.t_name_asc},
        {id: 6, name: window.t_name_desc},
      ]    
    }
  }
}
</script>

<style>

</style>
