<template>
  <div>
    <div class="unige-search-group position-relative d-flex align-items-stretch h-100">
      <span class="material-symbols-outlined position-absolute py-2 px-1">search</span>
      <input
        v-model="search"
        class="border-0 pl-5"
        placeholder="Rechercher un produit"
        type="text"
      >
      <span
        v-if="search && search.length > 2"
        class="material-symbols-outlined position-absolute cursor-pointer py-2 px-3 uni-search-group__remove"
        @click="reset()"
      >close</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchField',
  props: {},
  data(){
    return {
      search: null
    }
  },
  mounted(){
    this.$watch("search", () => {
      if (this.search && this.search.length > 2){
        this.$root.$emit('search-product', this.search)
      }else{
        this.$root.$emit('reset-search')
      }
    })
  },
  methods: {
    reset(){
      this.search = null
      this.$root.$emit('reset-search')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.uni-search-group__remove {
  right: 0;
}
</style>