<template>
  <div>
    <ul class="d-flex flex-wrap my-5 unige-home-categories">
      <li
        class="unige-home-categories__item px-3 py-2"
        :class="!current ? 'active' : ''"
        @click="filterCat(null)"
      >
        Tous
      </li>
      <li
        v-for="category in categories" 
        :key="category.id_category" 
        class="unige-home-categories__item px-3 py-2" 
        :class="current === category.id_category ? 'active' : ''"
        @click="filterCat(category.id_category)"
      >
        {{ category.name }}
      </li>
    </ul>
  </div>
</template>
  
<script>
export default {
  name: 'CategoryList',
  props: {},
  data(){
    return {
      categories: window.unige_categories,
      current: null,
    }
  },
  mounted(){
    this.$root.$on('init-cat', (id) => {
      this.current = parseInt(id)
    })
  },
  methods: {
    filterCat(id){
      this.current = id
      this.$root.$emit('filter-cat', id)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.unige-home-categories {
  gap: 0.25rem;
}
</style>