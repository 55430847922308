<template>
  <div>
    <div class="unige-sort-group d-flex align-items-center">
      <span class="material-symbols-outlined absolute py-2 px-3">sort</span>
      <select
        v-model="selectedSort"
        class="border-0 unige-sort-group__select"
        @change="onChange($event)"
      >
        <option
          v-for="sort in sortObject"
          :key="sort.id"
          :value="sort.id"
        >
          {{ sort.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SortField',
  props: {
    sortObject: {
      type: Array,
      required: true,
    }
  },
  data(){
    return {
      selectedSort: 1,
    }
  },
  mounted(){
    
  }, 
  methods: {
    onChange($event){
      this.$root.$emit('sort-product', this.selectedSort)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.unige-sort-group__select {
    background-color: #FFFFFF;
}
</style>